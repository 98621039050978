import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SessionService, BrandsService } from '../../services';
import { Sidebar, FormInput } from '../../components';

const BrandEdit = () => {
  const navigate = useNavigate();
  const { brandId } = useParams();
  const [name, setName] = useState('');
  const [models, setModels] = useState([]);
  const [modelId, setModelId] = useState('');
  const [modelName, setModelName] = useState('');

  useEffect(() => {
    const getData = async () => {
      const data = await BrandsService.getBrand(brandId);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.errorCode === 13) {
        SessionService.clearSession();
      }

      if (data.results) {
        setName(() => data.results.name);
        setModels(() => data.results.models);
      }
    };

    getData();
  }, [brandId]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!name) {
      alert('Please fill all the required fields');
      return;
    }

    const brand = {
      name,
      models,
    };

    const data = await BrandsService.updateBrand(brandId, brand);

    if (data.results) {
      alert('Updated successfully');
    } else {
      alert('Something went wrong');
    }
  };

  const handleDelete = async e => {
    e.preventDefault();

    if (window.confirm(`Delete ${brandId} brand?`)) {
      await BrandsService.deleteBrand(brandId);

      alert('Removed successfully');
      navigate('/brands');
    }
  };

  const handleModelDelete = (e, modelId) => {
    e.preventDefault();

    if (window.confirm(`Delete ${modelId} model?`)) {
      const newModels = models.filter(model => model._id !== modelId);
      setModels(() => newModels);
      alert('Save to apply changes');
    }
  };

  const handleModelSubmit = async e => {
    e.preventDefault();

    if (!modelId || !modelName) {
      alert('Please fill all the required fields');
      return;
    }

    const newModels = [...models, { _id: modelId, name: modelName }];
    setModels(() => newModels);
    alert('Save to apply changes');
  };

  return (
    <div className="flex h-screen overflow-hidden bg-white rounded-lg">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        {brandId && (
          <main className="relative flex-1 overflow-y-auto focus:outline-none">
            <div className="py-6">
              <div className="px-4 w-full sm:px-6 md:px-8">
                <h1 className="text-2xl mb-8 flex flex-row">
                  <Link to="/brands">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 mt-2 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                  </Link>

                  <span className="mt-1">{brandId}</span>
                </h1>

                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 grid-cols-3">
                    <div className="form-section">
                      <h2 className="text-lg font-semibold pb-2 mb-4 border-b">General</h2>
                      <FormInput
                        label="Name"
                        type="text"
                        placeholder="e.g. Yugo"
                        required={true}
                        value={name}
                        onChange={text => setName(text)}
                      />
                    </div>
                  </div>

                  <div className="flex mt-4">
                    <input type="submit" value="Save" className="btn min-w-[120px]" />
                    <button type="button" className="btn-delete min-w-[120px] ml-4" onClick={handleDelete}>
                      Delete
                    </button>
                  </div>
                </form>

                <h4 className="text-md font-semibold mt-16 mb-4">Models {models.length > 0 && <span>({models.length})</span>}</h4>
                <table className="table-auto w-full">
                  <thead className="text-left text-xs branding-wide border-b bg-gray-100">
                    <tr>
                      <th className="p-2 whitespace-nowrap text-left">ID</th>
                      <th className="p-2 whitespace-nowrap text-left">Name</th>
                      <th className="p-2 whitespace-nowrap text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-300">
                    {models.length > 0 ? (
                      models.map(model => (
                        <tr key={model._id}>
                          <td className="p-2 whitespace-nowrap text-left">
                            {/* <Link to={model._id}>{model._id}</Link> */}
                            {model._id}
                          </td>
                          <td className="p-2 whitespace-nowrap text-left">{model.name}</td>
                          <td className="p-2 whitespace-nowrap text-center">
                            <button type="button" onClick={e => handleModelDelete(e, model._id)}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-8 text-center text-gray-400" colSpan={13}>
                          No models in database
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <form onSubmit={handleModelSubmit}>
                  <div className="grid mt-4">
                    <div className="form-section">
                      <h2 className="text-lg font-semibold pb-2 mb-4 border-b">Add new model</h2>
                      <FormInput label="ID" type="text" placeholder="e.g. YUGO45" required={true} onChange={text => setModelId(text)} />
                      <FormInput
                        label="Name"
                        type="text"
                        placeholder="e.g. Yugo 45"
                        required={true}
                        onChange={text => setModelName(text)}
                      />
                    </div>
                  </div>

                  <div className="flex mt-4">
                    <input type="submit" value="Add" className="btn min-w-[120px]" />
                  </div>
                </form>
              </div>
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default BrandEdit;
