import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TracksService } from '../../services';
import { Sidebar, FormInput, FormTextArea, DropDown, Switch } from '../../components';
import countries from '../../data/countries.json';

const TrackAdd = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [variantName, setVariantName] = useState('');
  const [country, setCountry] = useState('');
  const [trackLength, setTrackLength] = useState(0);
  const [trackCenterCoordianteLong, setTrackCenterCoordianteLong] = useState(0);
  const [trackCenterCoordianteLat, setTrackCenterCoordianteLat] = useState(0);
  const [coordinates, setCoordinates] = useState('');
  const [startEqualsFinish, setStartEqualsFinish] = useState(false);
  const [startLocationLong, setStartLocationLong] = useState(0);
  const [startLocationLat, setStartLocationLat] = useState(0);
  const [startName, setStartName] = useState('');
  const [startHeading, setStartHeading] = useState(0);
  const [finishName, setFinishName] = useState('');
  // const [sectors, setSectors] = useState([]);
  const sectors = [];
  const [isActive, setIsActive] = useState(false);

  const countriesList = [];
  for (const c of countries) {
    countriesList.push({
      key: c._id,
      value: c.name,
    });
  }
  countriesList.sort((a, b) => a.value.localeCompare(b.value));

  const handleSubmit = async e => {
    e.preventDefault();

    if (!name) {
      alert('Please fill all the required fields');
      return;
    }

    const track = {
      name,
      variantName,
      country,
      trackLength,
      trackCenterCoordianteLong,
      trackCenterCoordianteLat,
      coordinates,
      startEqualsFinish,
      startLocationLong,
      startLocationLat,
      startName,
      startHeading,
      finishName,
      sectors,
      isActive,
    };

    const data = await TracksService.addTrack(track);

    if (data.results) {
      navigate('/tracks');
    } else {
      alert('Something went wrong');
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-white rounded-lg">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="px-4 w-full sm:px-6 md:px-8">
              <h1 className="text-2xl mb-8 flex flex-row">
                <Link to="/tracks">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 mt-2 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                  </svg>
                </Link>

                <span className="mt-1">Add new track</span>
              </h1>

              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 grid-cols-3">
                  <div className="form-section">
                    <h2 className="text-lg font-semibold pb-2 mb-4 border-b">General</h2>
                    <FormInput label="Name" type="text" placeholder="e.g. Navak" onChange={text => setName(text)} />
                    <FormInput
                      label="Variant name"
                      type="text"
                      placeholder="e.g. International Track"
                      onChange={text => setVariantName(text)}
                    />
                    <DropDown
                      label="Country"
                      placeholder="Please choose a country"
                      values={countriesList}
                      value={country}
                      onChange={text => setCountry(text)}
                    />
                    <Switch title="Is Active?" value={isActive} onChange={selected => setIsActive(selected)} />
                  </div>

                  <div className="form-section">
                    <h2 className="text-lg font-semibold pb-2 mb-4 border-b">Track info</h2>
                    <FormInput
                      label="Track length"
                      type="number"
                      step="1"
                      placeholder="e.g. 2000"
                      onChange={text => setTrackLength(text)}
                    />
                    <FormInput
                      label="Track center coordiante long"
                      type="number"
                      step="0.000000001"
                      placeholder="e.g. -4,500715"
                      onChange={text => setTrackCenterCoordianteLong(text)}
                    />
                    <FormInput
                      label="Track center coordiante lat"
                      type="number"
                      step="0.000000001"
                      placeholder="e.g. 53,191258"
                      onChange={text => setTrackCenterCoordianteLat(text)}
                    />
                    <FormTextArea
                      label="Coordinates"
                      placeholder="e.g. 39.7931632,-86.2388662|39.7932082,-86.2388665|39.7932532,-86.2388668|39.7932981,-86.23886709999999|39.7933431,-86.23886760000001|39.7933762,-86.2388679|39.7934212,-86.2388684|39.7934661,-86.2388689|39...."
                      onChange={text => setCoordinates(text)}
                    />
                  </div>

                  <div className="form-section">
                    <h2 className="text-lg font-semibold pb-2 mb-4 border-b">Start info</h2>
                    <FormInput
                      label="Start location long"
                      type="number"
                      step="0.000000001"
                      placeholder="e.g. 13,9282091"
                      onChange={text => setStartLocationLong(text)}
                    />
                    <FormInput
                      label="Start location lat"
                      type="number"
                      step="0.000000001"
                      placeholder="e.g. 51,5350823"
                      onChange={text => setStartLocationLat(text)}
                    />
                    <FormInput label="Start name" type="text" placeholder="e.g. Start name" onChange={text => setStartName(text)} />
                    <FormInput
                      label="Start heading"
                      type="number"
                      step="0.000000001"
                      placeholder="e.g. 77,79"
                      onChange={text => setStartHeading(text)}
                    />
                    <FormInput label="Finish name" type="text" placeholder="e.g. 97,99" onChange={text => setFinishName(text)} />
                    <Switch title="Start equals finish?" value={startEqualsFinish} onChange={selected => setStartEqualsFinish(selected)} />
                    {/* sectors */}
                  </div>
                </div>

                <div className="flex mt-4">
                  <input type="submit" value="Save" className="btn min-w-[120px]" />
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TrackAdd;
