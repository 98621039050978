import { useEffect, useRef } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { authStore } from '../store/authStore';
import { SessionService } from '../services';

const RequireAuth = () => {
  const setAuthStoreSession = useSetRecoilState(authStore);
  const location = useLocation();
  const session = useRef(SessionService.getSessionFromStorage());

  useEffect(() => {
    if (SessionService.isSessionValid()) {
      setAuthStoreSession(() => session.current);
      return;
    }

    SessionService.clearSession();
    setAuthStoreSession(() => null);
  }, [location, setAuthStoreSession]);

  if (SessionService.isSessionValid()) {
    return <Outlet />;
  } else {
    return <Navigate to={'/'} replace={true} />;
  }
};

export default RequireAuth;
