import { SessionService } from './SessionService';

const API_URL = process.env.REACT_APP_API_URL;

const getAllTracks = async (skip, limit, fields, q) => {
  try {
    const params = new URLSearchParams();
    if (skip) params.append('skip', skip);
    if (limit) params.append('limit', limit);
    if (fields) params.append('fields', fields);
    if (q) params.append('q', q);
    
    const response = await fetch(`${API_URL}/admin/tracks?${new URLSearchParams(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: TracksService.js ~ error', error);
    return { status: 500 };
  }
};

const getTrack = async id => {
  try {
    const response = await fetch(`${API_URL}/admin/tracks/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: TracksService.js ~ error', error);
    return { status: 500 };
  }
};

const addTrack = async data => {
  try {
    const response = await fetch(`${API_URL}/admin/tracks`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: TracksService.js ~ error', error);
    return { status: 500 };
  }
};

const updateTrack = async (id, data) => {
  try {
    const response = await fetch(`${API_URL}/admin/tracks/${id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: TracksService.js ~ error', error);
    return { status: 500 };
  }
};

const deleteTrack = async id => {
  try {
    const response = await fetch(`${API_URL}/admin/tracks/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: TracksService.js ~ error', error);
    return { status: 500 };
  }
};

export const TracksService = {
  getAllTracks,
  getTrack,
  addTrack,
  updateTrack,
  deleteTrack,
};
