import { SessionService } from './SessionService';

const API_URL = process.env.REACT_APP_API_URL;

const getAllSessions = async (skip, limit, sort, order, q) => {
  try {
    const params = new URLSearchParams();
    if (skip) params.append('skip', skip);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    if (q) params.append('q', q);

    const response = await fetch(`${API_URL}/admin/sessions?${new URLSearchParams(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: SessionService.js ~ error', error);
    return { status: 500 };
  }
};

const getSession = async id => {
  try {
    const response = await fetch(`${API_URL}/admin/sessions/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: SessionService.js ~ error', error);
    return { status: 500 };
  }
};

const updateSession = async (id, data) => {
  try {
    const response = await fetch(`${API_URL}/admin/sessions/${id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: SessionService.js ~ error', error);
    return { status: 500 };
  }
};

const deleteSession = async id => {
  try {
    const response = await fetch(`${API_URL}/admin/sessions/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: SessionService.js ~ error', error);
    return { status: 500 };
  }
};

export const SessionsService = {
  getAllSessions,
  getSession,
  updateSession,
  deleteSession,
};
