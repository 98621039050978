import { SessionService } from './SessionService';

const API_URL = process.env.REACT_APP_API_URL;

const getAllBrands = async (skip, limit, q) => {
  try {
    const params = new URLSearchParams();
    if (skip) params.append('skip', skip);
    if (limit) params.append('limit', limit);
    if (q) params.append('q', q);

    const response = await fetch(`${API_URL}/admin/brands?${new URLSearchParams(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: BrandsService.js ~ error', error);
    return { status: 500 };
  }
};

const getBrand = async id => {
  try {
    const response = await fetch(`${API_URL}/admin/brands/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: BrandsService.js ~ error', error);
    return { status: 500 };
  }
};

const addBrand = async data => {
  try {
    const response = await fetch(`${API_URL}/admin/brands`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: BrandsService.js ~ error', error);
    return { status: 500 };
  }
};

const updateBrand = async (id, data) => {
  try {
    const response = await fetch(`${API_URL}/admin/brands/${id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: BrandsService.js ~ error', error);
    return { status: 500 };
  }
};

const deleteBrand = async id => {
  try {
    const response = await fetch(`${API_URL}/admin/brands/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: BrandsService.js ~ error', error);
    return { status: 500 };
  }
};

export const BrandsService = {
  getAllBrands,
  getBrand,
  addBrand,
  updateBrand,
  deleteBrand,
};
