import { SessionService } from './SessionService';

const API_URL = process.env.REACT_APP_API_URL;

const signIn = async (username, password) =>
  fetch(`${API_URL}/admin/users/signin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  }).then(response => response.json());

const getAllUsers = async (skip, limit, sort, order, q) => {
  try {
    const params = new URLSearchParams();
    if (skip) params.append('skip', skip);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    if (q) params.append('q', q);

    const response = await fetch(`${API_URL}/admin/users?${new URLSearchParams(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: UserService.js ~ error', error);
    return { status: 500 };
  }
};

const getProfile = async id => {
  try {
    const response = await fetch(`${API_URL}/admin/users/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: UserService.js ~ error', error);
    return { status: 500 };
  }
};

const updateUser = async (id, data) => {
  try {
    const response = await fetch(`${API_URL}/admin/users/${id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: UserService.js ~ error', error);
    return { status: 500 };
  }
};

const deleteUser = async id => {
  try {
    const response = await fetch(`${API_URL}/admin/users/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: UserService.js ~ error', error);
    return { status: 500 };
  }
};

export const UserService = {
  signIn,
  getAllUsers,
  getProfile,
  updateUser,
  deleteUser,
};
