import { SessionService } from './SessionService';

const API_URL = process.env.REACT_APP_API_URL;

const upload = async asset => {
  const data = new FormData();
  data.append('file', asset);

  try {
    const response = await fetch(`${API_URL}/uploads/file`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: data,
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: UploadService.js ~ create ~ error', error);
  }
};

export const UploadService = {
  upload,
};
