import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SessionService, SessionsService, TracksService, VehiclesService } from '../../services';
import { Sidebar, FormInput, DropDown, Switch } from '../../components';
import { format } from 'date-fns';

const SessionEdit = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const [tracks, setTracks] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [track, setTrack] = useState('');
  const [vehicle, setVehicle] = useState('');
  const [laps, setLaps] = useState('');
  const [startedRecordingTimestamp, setStartedRecordingTimestamp] = useState('');
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const data = await SessionsService.getSession(sessionId);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.errorCode === 13) {
        SessionService.clearSession();
      }

      if (data.results) {
        setTrack(() => data.results.track || '');
        setVehicle(() => data.results.vehicle || '');
        setLaps(() => data.results.laps || '');
        setStartedRecordingTimestamp(() => data.results.startedRecordingTimestamp || '');
        setIsActive(() => data.results.isActive || false);
      }
    };

    const getTracksData = async () => {
      const data = await TracksService.getAllTracks(0, 1000, '_id name variantName country location isActive');

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.errorCode === 13) {
        SessionService.clearSession();
      }

      if (data.results) {
        const tracksList = [];
        for (const track of data.results) {
          let name = track.name;
          name += track.variantName && ` (${track.variantName})`;
          name += track.country && `, ${track.country}`;

          tracksList.push({
            key: track._id,
            value: name,
          });
        }

        setTracks(() => tracksList);
      }
    };

    const getVehiclesData = async () => {
      const data = await VehiclesService.getAllVehicles(0, 100000);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.errorCode === 13) {
        SessionService.clearSession();
      }

      if (data.results) {
        const vehiclesList = [];
        for (const vehicle of data.results) {
          vehiclesList.push({
            key: vehicle._id,
            value: `${vehicle.brand?.name}, ${vehicle.model?.name}, ${vehicle.year}, User: ${vehicle.user?.name}`,
          });
        }

        setVehicles(() => vehiclesList);
      }
    };

    getData();
    getTracksData();
    getVehiclesData();
  }, [sessionId]);

  const handleSubmit = async e => {
    e.preventDefault();

    const session = {
      track,
      vehicle,
      laps,
      startedRecordingTimestamp,
      isActive,
    };
    const data = await SessionsService.updateSession(sessionId, session);

    if (data.results) {
      alert('Updated successfully');
    } else {
      alert('Something went wrong');
    }
  };

  const handleDelete = async e => {
    e.preventDefault();

    if (window.confirm(`Delete ${sessionId}'s session?`)) {
      await SessionsService.deleteSession(sessionId);

      alert('Removed successfully');
      navigate('/sessions');
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-white rounded-lg">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        {sessionId && (
          <main className="relative flex-1 overflow-y-auto focus:outline-none">
            <div className="py-6">
              <div className="w-full px-4 sm:px-6 md:px-8">
                <h1 className="flex flex-row mb-8 text-2xl">
                  <Link to="/sessions">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 mt-2 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                  </Link>

                  <span className="mt-1">{sessionId}</span>
                </h1>

                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="form-section">
                      <h2 className="pb-2 mb-4 text-lg font-semibold border-b">General</h2>

                      <DropDown
                        label="Track"
                        placeholder="Please choose a track"
                        values={tracks}
                        value={track}
                        onChange={text => setTrack(text)}
                      />

                      <DropDown
                        label="Vehicle"
                        placeholder="Please choose a vehicle"
                        values={vehicles}
                        value={vehicle}
                        onChange={text => setVehicle(text)}
                      />

                      <FormInput
                        label="Started Recording Timestamp"
                        type="number"
                        step="1"
                        placeholder="e.g. Time in seconds"
                        value={startedRecordingTimestamp}
                        onChange={text => setStartedRecordingTimestamp(text)}
                      />
                      <Switch title="Is Active?" value={isActive} onChange={selected => setIsActive(selected)} />
                    </div>
                  </div>

                  <div className="flex mt-4">
                    <input type="submit" value="Save" className="btn min-w-[120px]" />
                    <button type="button" className="btn-delete min-w-[120px] ml-4" onClick={handleDelete}>
                      Delete
                    </button>
                  </div>
                </form>

                <h4 className="mt-16 mb-4 font-semibold text-md">Laps {laps.length > 0 && <span>({laps.length})</span>}</h4>
                <table className="w-full table-auto">
                  <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                    <tr>
                      <th className="p-2 text-left whitespace-nowrap">ID</th>
                      <th className="p-2 text-center whitespace-nowrap">Timestamp start</th>
                      <th className="p-2 text-center whitespace-nowrap">Timestamp end</th>
                      <th className="p-2 text-center whitespace-nowrap">Lap time</th>
                      <th className="p-2 text-left whitespace-nowrap">GPS source</th>
                      <th className="p-2 text-center whitespace-nowrap">GPS refresh rate</th>
                      <th className="p-2 text-left whitespace-nowrap">Video</th>
                      {/* <th className="p-2 text-left whitespace-nowrap">videoTimeStart</th>
                            <th className="p-2 text-left whitespace-nowrap">videoTimeEnd</th> */}
                      <th className="p-2 text-center whitespace-nowrap">Top speed</th>
                      <th className="p-2 text-right whitespace-nowrap">Date</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-300">
                    {laps.length > 0 ? (
                      laps.map(lap => (
                        <tr key={lap._id}>
                          <td className="p-2 text-left whitespace-nowrap">{lap._id}</td>
                          <td className="p-2 text-center whitespace-nowrap">{lap.timestampStart}</td>
                          <td className="p-2 text-center whitespace-nowrap">{lap.timestampEnd}</td>
                          <td className="p-2 text-center whitespace-nowrap">{lap.lapTime}</td>
                          <td className="p-2 text-left whitespace-nowrap">{lap.gpsSource}</td>
                          <td className="p-2 text-center whitespace-nowrap">{lap.gpsRefreshRate}</td>
                          <td className="p-2 text-left whitespace-nowrap">
                            {lap.video && (
                              <a href={lap.video} target="_blank" rel="noopener noreferrer">
                                {lap.video}
                              </a>
                            )}
                          </td>
                          {/* <td className="p-2 text-left whitespace-nowrap">{lap.videoTimeStart}</td>
                                <td className="p-2 text-left whitespace-nowrap">{lap.videoTimeEnd}</td> */}
                          <td className="p-2 text-center whitespace-nowrap">{lap.topSpeed}</td>
                          <td className="p-2 text-right whitespace-nowrap">{format(new Date(lap.datetime), 'dd/MM/yyyy')}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-8 text-center text-gray-400" colSpan={13}>
                          No laps in session
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default SessionEdit;
