import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { SessionService, TracksService } from '../../services';
import { Sidebar } from '../../components';
import { format } from 'date-fns';
import TableSearch from '../../components/TableSearch';

const TracksList = () => {
  const [tracks, setTracks] = useState([]);
  const [search, setSearch] = useState('');
  const [pageCountShow, setPageCountShow] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageCurrent, setPageCurrent] = useState(0);
  const pageLimit = 100;

  useEffect(() => {
    const getData = async () => {
      const data = await TracksService.getAllTracks(
        pageCurrent,
        pageLimit,
        '_id name variantName country location trackLength isActive createdAt updatedAt',
        search
      );

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.results) {
        setTracks(() => data.results);
        setPageCount(() => data.count);
        setPageCountShow(() => Math.ceil(data.count / pageLimit));
      }
    };

    getData();
  }, [pageCurrent, search]);

  const handlePageChange = async event => {
    const data = await TracksService.getAllTracks(
      event.selected,
      pageLimit,
      '_id name variantName country location trackLength isActive createdAt updatedAt',
      search
    );

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data.results) {
      setTracks(() => data.results);
      setPageCount(() => data.count);
      setPageCountShow(() => Math.ceil(data.count / pageLimit));
      setPageCurrent(event.selected);
    }
  };

  const handleSearch = async text => {
    setSearch(() => text);

    const data = await TracksService.getAllTracks(
      pageCurrent,
      pageLimit,
      '_id name variantName country location trackLength isActive createdAt updatedAt',
      text
    );

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data.results) {
      setTracks(() => data.results);
      setPageCount(() => data.count);
      setPageCountShow(() => Math.ceil(data.count / pageLimit));
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <div className="flex items-center justify-between w-full mt-2 mb-4">
                <div className="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                    />
                  </svg>
                  <h1 className="text-xl font-semibold">Tracks {pageCount > 0 && <span>({pageCount})</span>}</h1>
                </div>

                <Link
                  to={'/tracks/new'}
                  className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                  Add new track
                </Link>
              </div>

              <TableSearch onChange={handleSearch} />

              <table className="w-full table-auto">
                <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                  <tr>
                    <th className="p-2 text-left whitespace-nowrap">ID</th>
                    <th className="p-2 text-left whitespace-nowrap">Name</th>
                    <th className="p-2 text-left whitespace-nowrap">Variant name</th>
                    <th className="p-2 text-left whitespace-nowrap">Country</th>
                    <th className="p-2 text-center whitespace-nowrap">Is active</th>
                    <th className="p-2 text-center whitespace-nowrap">Location</th>
                    <th className="p-2 text-center whitespace-nowrap">Created</th>
                    <th className="p-2 text-center whitespace-nowrap">Updated</th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-300">
                  {tracks.length > 0 ? (
                    tracks.map(track => (
                      <tr key={track._id}>
                        <td className="p-2 text-left whitespace-nowrap">
                          <Link to={track._id}>{track._id}</Link>
                        </td>
                        <td className="p-2 text-left whitespace-nowrap">{track.name}</td>
                        <td className="p-2 text-left whitespace-nowrap">{track.variantName}</td>
                        <td className="p-2 text-left whitespace-nowrap">{track.country}</td>
                        <td className="p-2 text-center whitespace-nowrap">
                          <span className={`${track.isActive && 'text-green-600'}`}>{track.isActive.toString()}</span>
                        </td>
                        <td className="p-2 text-left whitespace-nowrap">
                          {
                            <a
                              href={`https://maps.google.com/?q=${track.location?.coordinates[1]},${track.location?.coordinates[0]}`}
                              target="_blank"
                              rel="noreferrer">
                              View map
                            </a>
                          }
                        </td>
                        <td className="p-2 text-center whitespace-nowrap">{format(new Date(track.createdAt), 'dd/MM/yyyy')}</td>
                        <td className="p-2 text-right whitespace-nowrap">{format(new Date(track.updatedAt), 'dd/MM/yyyy')}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-8 text-center text-gray-400" colSpan={13}>
                        No tracks in database
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageChange}
                pageRangeDisplayed={pageCount}
                pageCount={pageCountShow}
                previousLabel="< prev"
                nextLabel="next >"
                className="flex items-center my-8"
                pageClassName="bg-gray-100 rounded-md m-1 text-sm font-semibold w-8 h-8 hover:bg-gray-200"
                pageLinkClassName="no-underline w-8 h-8 px-2 py-2 flex justify-center items-center"
                previousClassName="bg-gray-100 rounded-md m-1 text-sm font-semibold h-8 hover:bg-gray-200"
                previousLinkClassName="no-underline h-8 px-2 py-2 flex justify-center items-center"
                nextClassName="bg-gray-100 rounded-md m-1 text-sm font-semibold h-8 hover:bg-gray-200"
                nextLinkClassName="no-underline h-8 px-2 py-2 flex justify-center items-center"
                activeClassName="bg-black"
                activeLinkClassName="text-white"
                disabledClassName="bg-gray-50 hover:bg-gray-50"
                disabledLinkClassName="text-gray-400 hover:text-gray-400"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TracksList;
