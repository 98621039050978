import React, { useId } from 'react';

const DropDown = ({ label, placeholder, values, required, value, onChange, showEmpty }) => {
  const id = useId();
  const isRequired = required && 'required';

  if (!values) {
    throw new Error('DropDown values are not implemented');
  }

  return (
    <div className="relative w-full px-3 py-3 -space-y-px bg-white border border-gray-300 rounded-md shadow-sm">
      <label htmlFor={id} className="block text-xs font-bold">
        {label} {required ? '*' : 'optional'}
      </label>
      <select
        id={id}
        name={id}
        className="w-full text-gray-900 placeholder-gray-500 bg-white appearance-none focus:outline-none focus:ring-gray-300 focus:border-black focus:z-10 sm:text-sm"
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
        required={isRequired}>
        <option value="" disabled={!showEmpty}>
          {placeholder}
        </option>
        {values.map(option => (
          <option key={option.key} value={option.key}>
            {option.value}
          </option>
        ))}
      </select>
      <div className="absolute right-0 flex items-center px-2 pointer-events-none bottom-4">
        <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
          <path
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
            fillRule="evenodd"></path>
        </svg>
      </div>
    </div>
  );
};

export default DropDown;
