import React, { useState, useEffect, useRef } from 'react';
import { UploadService } from '../services';

const UploadPhoto = ({ initPhoto, onSubmit }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [photo, setPhoto] = useState();
  const photoInput = useRef(null);

  useEffect(() => {
    if (initPhoto) {
      setPhoto(initPhoto);
    }
  }, [initPhoto]);

  const handleOpenFileDialog = () => {
    photoInput.current.click();
  };

  const handleUpload = async file => {
    setIsUploading(() => true);
    const uploadedImage = await UploadService.upload(file);

    if (uploadedImage) {
      setIsUploading(() => false);
      setPhoto(() => uploadedImage.results.url);
      onSubmit(uploadedImage.results.url);
    }
  };

  return (
    <div
      className="flex flex-col text-xs pb-4 text-center justify-center items-center cursor-pointer"
      onClick={() => handleOpenFileDialog()}>
      <div className="flex rounded-full object-cover w-32 h-32 bg-gray-600 border-gray-300 border-2 overflow-clip mb-1 justify-center items-center hover:border-black">
        {!photo ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        ) : (
          <img className="mx-auto object-cover w-full h-full" src={photo} alt="Upload new" />
        )}
        <input ref={photoInput} type="file" className="hidden" onChange={e => handleUpload(e.target.files[0])} />
      </div>
      {isUploading ? (
        <span className="flex rounded-md bg-black text-white px-2 py-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
          Uploading...
        </span>
      ) : (
        <span className="flex rounded-md bg-black text-white px-2 py-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          Change photo
        </span>
      )}
    </div>
  );
};

export default UploadPhoto;
