import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BrandsService } from '../../services';
import { Sidebar, FormInput } from '../../components';

const BrandAdd = () => {
  const navigate = useNavigate();
  const [id, setID] = useState('');
  const [name, setName] = useState('');
  const [models] = useState([]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!name) {
      alert('Please fill all the required fields');
      return;
    }

    const brand = {
      _id: id,
      name,
      models,
    };

    const data = await BrandsService.addBrand(brand);

    if (data.results) {
      navigate('/brands');
    } else {
      alert('Something went wrong');
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-white rounded-lg">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <h1 className="flex flex-row mb-8 text-2xl">
                <Link to="/brands">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 mt-2 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                  </svg>
                </Link>

                <span className="mt-1">Add new brand</span>
              </h1>

              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-3 gap-4">
                  <div className="form-section">
                    <h2 className="pb-2 mb-4 text-lg font-semibold border-b">General</h2>
                    <FormInput label="ID" type="text" placeholder="e.g. YUGO" onChange={text => setID(text)} />
                    <FormInput label="Name" type="text" placeholder="e.g. Yugo" onChange={text => setName(text)} />
                  </div>
                </div>

                <div className="flex mt-4">
                  <input type="submit" value="Save" className="btn min-w-[120px]" />
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default BrandAdd;
