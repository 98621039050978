import React, { useId } from 'react';
import './Switch.css';

const Switch = ({ title, value, onChange }) => {
  const id = useId();
  const isChecked = value ? 'checked' : '';

  const handleChange = () => {
    onChange(!value);
  };

  return (
    <div className="align-middle p-1">
      <label htmlFor={id} className="font-bold text-xs">
        {title}
      </label>
      <div className="relative inline-block w-10 ml-2 align-middle select-none transition duration-200 ease-in">
        <input
          id={id}
          name={id}
          type="checkbox"
          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-2 appearance-none cursor-pointer checked:right-0"
          onChange={handleChange}
          checked={isChecked}
        />
        <label htmlFor={id} className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
      </div>
    </div>
  );
};

export default Switch;
