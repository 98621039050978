const TableSearch = ({ onChange }) => {
  const handleSearch = newValue => {
    onChange(newValue);
  };

  return (
    <div className="w-full py-4 text-xs text-left">
      <div className="relative flex w-1/3 px-1 py-1 -space-y-px text-xs bg-white border border-gray-300 rounded shadow-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 mr-1 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
        <input
          className="w-full text-gray-900 placeholder-gray-500 appearance-none focus:outline-none focus:fill-transparent focus:z-10 sm:text-xs"
          placeholder="Search"
          onChange={e => handleSearch(e.target.value)}
        />
      </div>
    </div>
  );
};

export default TableSearch;
