import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SessionService, UserService } from '../../services';
import { Sidebar, FormInput, DropDown, Switch, UploadPhoto } from '../../components';
import { format } from 'date-fns';

const UserEdit = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [avatar, setAvatar] = useState('');
  const [email, setEmail] = useState('');
  const [appleId, setAppleId] = useState('');
  const [appleToken, setAppleToken] = useState('');
  const [googleId, setGoogleId] = useState('');
  const [googleToken, setGoogleToken] = useState('');
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [unit, setUnit] = useState('');
  const [isPro, setIsPro] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const data = await UserService.getProfile(userId);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.errorCode === 13) {
        SessionService.clearSession();
      }

      if (data.results) {
        setAvatar(() => data.results.avatar || undefined);
        setEmail(() => data.results.email || '');
        setAppleId(() => data.results.appleId || '');
        setAppleToken(() => data.results.appleToken || '');
        setGoogleId(() => data.results.googleId || '');
        setGoogleToken(() => data.results.googleToken || '');
        setName(() => data.results.name || '');
        setUsername(() => data.results.username || '');
        setUnit(() => data.results.unit || '');
        setIsPro(() => data.results.isPro || false);
        setIsActive(() => data.results.isActive || false);
        setIsAdmin(() => data.results.isAdmin || false);
        setVehicles(() => data.results.vehicles || []);
      }
    };

    getData();
  }, [userId]);

  const handleSubmit = async e => {
    e.preventDefault();

    const user = {
      avatar,
      email,
      appleId,
      appleToken,
      googleId,
      googleToken,
      name,
      username,
      unit,
      isPro,
      isActive,
      isAdmin,
    };
    const data = await UserService.updateUser(userId, user);

    if (data.results) {
      alert('Updated successfully');
    } else {
      alert('Something went wrong');
    }
  };

  const handleDelete = async e => {
    e.preventDefault();

    if (window.confirm(`Delete ${name}'s profile?`)) {
      await UserService.deleteUser(userId);

      alert('Removed successfully');
      navigate('/users');
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-white rounded-lg">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        {userId && (
          <main className="relative flex-1 overflow-y-auto focus:outline-none">
            <div className="py-6">
              <div className="w-full px-4 sm:px-6 md:px-8">
                <h1 className="flex flex-row mb-8 text-2xl">
                  <Link to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 mt-2 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                  </Link>

                  <span className="mt-1">{name}</span>
                </h1>

                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="form-section">
                      <h2 className="pb-2 mb-4 text-lg font-semibold border-b">General</h2>

                      <UploadPhoto initPhoto={avatar} onSubmit={url => setAvatar(url)} />

                      <FormInput
                        label="Email"
                        type="email"
                        placeholder="e.g. Email Address"
                        value={email}
                        onChange={text => setEmail(text)}
                      />

                      <FormInput label="Name" type="text" placeholder="e.g. Name" value={name} onChange={text => setName(text)} />

                      <FormInput
                        label="Username"
                        type="text"
                        placeholder="e.g. Username"
                        value={username}
                        onChange={text => setUsername(text)}
                      />

                      <FormInput
                        label="Apple ID"
                        type="text"
                        placeholder="e.g. Apple ID"
                        value={appleId}
                        onChange={text => setEmail(text)}
                      />

                      <DropDown
                        label="Unit"
                        placeholder="Please choose a unit"
                        values={[
                          {
                            key: 'imperial',
                            value: 'imperial',
                          },
                          {
                            key: 'metric',
                            value: 'metric',
                          },
                        ]}
                        value={unit}
                        onChange={text => setUnit(text)}
                      />
                    </div>

                    <div className="form-section">
                      <h2 className="pb-2 mb-4 text-lg font-semibold border-b">Social</h2>
                      <FormInput
                        label="Apple Token"
                        type="text"
                        placeholder="e.g. Apple Token"
                        value={appleToken}
                        onChange={text => setAppleToken(text)}
                      />

                      <FormInput
                        label="Google ID"
                        type="text"
                        placeholder="e.g. Google ID"
                        value={googleId}
                        onChange={text => setGoogleId(text)}
                      />

                      <FormInput
                        label="Google Token"
                        type="text"
                        placeholder="e.g. Google Token"
                        value={googleToken}
                        onChange={text => setGoogleToken(text)}
                      />
                    </div>

                    <div className="form-section">
                      <h2 className="pb-2 mb-4 text-lg font-semibold border-b">State</h2>

                      <Switch title="Is Pro?" value={isPro} onChange={selected => setIsPro(selected)} />

                      <Switch title="Is Active?" value={isActive} onChange={selected => setIsActive(selected)} />

                      <Switch title="Is Admin?" value={isAdmin} onChange={selected => setIsAdmin(selected)} />
                    </div>
                  </div>

                  <div className="flex mt-4">
                    <input type="submit" value="Save" className="btn min-w-[120px]" />
                    <button type="button" className="btn-delete min-w-[120px] ml-4" onClick={handleDelete}>
                      Delete
                    </button>
                  </div>
                </form>

                <h4 className="mt-16 mb-4 font-semibold text-md">Vehicles {vehicles.length > 0 && <span>({vehicles.length})</span>}</h4>
                <table className="w-full table-auto">
                  <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                    <tr>
                      <th className="p-2 text-left whitespace-nowrap">Brand, Model &amp; Variant</th>
                      <th className="p-2 text-center whitespace-nowrap">Drivetrain</th>
                      <th className="p-2 text-center whitespace-nowrap">Year</th>
                      <th className="p-2 text-center whitespace-nowrap">Transmission</th>
                      <th className="p-2 text-center whitespace-nowrap">Power</th>
                      <th className="p-2 text-center whitespace-nowrap">Weight</th>
                      <th className="p-2 text-center whitespace-nowrap">Is default</th>
                      <th className="p-2 text-center whitespace-nowrap">Mods</th>
                      <th className="p-2 text-center whitespace-nowrap">Created</th>
                      <th className="p-2 text-center whitespace-nowrap">Updated</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-300">
                    {vehicles.length > 0 ? (
                      vehicles.map(vehicle => (
                        <tr key={vehicle._id}>
                          <td className="p-2 text-left whitespace-nowrap">
                            <Link to={`/vehicles/${vehicle._id}`}>
                              {vehicle.brand} {vehicle.model} {vehicle.variant}
                            </Link>
                          </td>
                          <td className="p-2 text-center whitespace-nowrap">{vehicle.drivetrain}</td>
                          <td className="p-2 text-center whitespace-nowrap">{vehicle.year}</td>
                          <td className="p-2 text-center whitespace-nowrap">{vehicle.transmission}</td>
                          <td className="p-2 text-center whitespace-nowrap">{vehicle.power}</td>
                          <td className="p-2 text-center whitespace-nowrap">{vehicle.weight}</td>
                          <td className="p-2 text-center whitespace-nowrap">
                            <span className={`${vehicle.isDefault && 'text-green-600'}`}>{vehicle.isDefault.toString()}</span>
                          </td>
                          <td className="p-2 text-center max-w-40">{vehicle.mods}</td>
                          <td className="p-2 text-center whitespace-nowrap">{format(new Date(vehicle.createdAt), 'dd/MM/yyyy')}</td>
                          <td className="p-2 text-right whitespace-nowrap">{format(new Date(vehicle.updatedAt), 'dd/MM/yyyy')}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-8 text-center text-gray-400" colSpan={13}>
                          No vehicles in database
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default UserEdit;
