import { Routes, Route } from 'react-router-dom';
import { RequireAuth } from './components';
import {
  NotFound,
  SignIn,
  UsersList,
  UserEdit,
  SessionsList,
  SessionEdit,
  VehiclesList,
  VehicleEdit,
  TracksList,
  TrackEdit,
  TrackAdd,
  BrandsList,
  BrandEdit,
  BrandAdd,
  RunsList,
  RunEdit,
} from './pages';

const App = () => (
  <Routes>
    <Route path="/" element={<SignIn />} />
    <Route element={<RequireAuth />}>
      <Route path="users" element={<UsersList />} />
      <Route path="users/:userId" element={<UserEdit />} />
      <Route path="sessions" element={<SessionsList />} />
      <Route path="sessions/:sessionId" element={<SessionEdit />} />
      <Route path="vehicles" element={<VehiclesList />} />
      <Route path="vehicles/:vehicleId" element={<VehicleEdit />} />
      <Route path="tracks" element={<TracksList />} />
      <Route path="tracks/new" element={<TrackAdd />} />
      <Route path="tracks/:trackId" element={<TrackEdit />} />
      <Route path="brands" element={<BrandsList />} />
      <Route path="brands/new" element={<BrandAdd />} />
      <Route path="brands/:brandId" element={<BrandEdit />} />
      <Route path="runs" element={<RunsList />} />
      <Route path="runs/:runId" element={<RunEdit />} />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default App;
