import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { SessionService, UserService } from '../services';
import { authStore } from '../store/authStore';
import { FormInput } from '../components';

const SignIn = () => {
  const navigate = useNavigate();
  const setSession = useSetRecoilState(authStore);
  // const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (SessionService.isSessionValid()) {
      navigate('/users');
    }
  }, [setSession, navigate]);

  const handleSignIn = async e => {
    e.preventDefault();

    // setIsLoading(() => true);
    const data = await UserService.signIn(username, password);
    // setIsLoading(() => false);

    if (data.errorCode) {
      alert('Wrong credentials');
      return;
    }

    if (data.results) {
      SessionService.saveSession(data.results, data.token);
      setSession(() => data.results);
      navigate('/users');
    }
  };

  return (
    <section>
      <div className="flex min-h-screen overflow-hidden">
        <div
          className="flex flex-col justify-center flex-1 px-4 py-12  sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="w-full max-w-xl mx-auto lg:w-96">
            <img src={`./logo.jpg`} alt="Race Buddy" className="object-cover w-24 mx-auto rounded-full" />
            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6" onSubmit={handleSignIn}>
                  <FormInput
                    label="Username"
                    type="text"
                    placeholder="Your username"
                    autoComplete="username"
                    required={true}
                    onChange={text => setUsername(text)}
                  />
                  <FormInput
                    label="Password"
                    type="password"
                    placeholder="Your password"
                    autoComplete="current-password"
                    required={true}
                    onChange={text => setPassword(text)}
                  />

                  <button type="submit" className="btn">
                    Sign in
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-1 hidden w-0 overflow-hidden lg:block">
          <img className="absolute inset-0 object-cover w-full h-full" src={`${process.env.PUBLIC_URL}/signin.jpg`} alt="RaceBuddy" />
        </div>
      </div>
    </section>
  );
};

export default SignIn;
