import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SessionService, TracksService } from '../../services';
import { Sidebar, FormInput, FormTextArea, DropDown, Switch } from '../../components';
import { format } from 'date-fns';
import countries from '../../data/countries.json';

const TrackEdit = () => {
  const navigate = useNavigate();
  const { trackId } = useParams();
  const [name, setName] = useState('');
  const [variantName, setVariantName] = useState('');
  const [country, setCountry] = useState('');
  const [trackLength, setTrackLength] = useState(0);
  const [trackCenterCoordianteLong, setTrackCenterCoordianteLong] = useState(0);
  const [trackCenterCoordianteLat, setTrackCenterCoordianteLat] = useState(0);
  const [coordinates, setCoordinates] = useState('');
  const [startEqualsFinish, setStartEqualsFinish] = useState(false);
  const [startLocationLong, setStartLocationLong] = useState(0);
  const [startLocationLat, setStartLocationLat] = useState(0);
  const [startName, setStartName] = useState('');
  const [startHeading, setStartHeading] = useState(0);
  const [finishName, setFinishName] = useState('');
  const [sectors, setSectors] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [createdAt, setCreatedAt] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');

  const countriesList = [];
  for (const c of countries) {
    countriesList.push({
      key: c._id,
      value: c.name,
    });
  }
  countriesList.sort((a, b) => a.value.localeCompare(b.value));

  useEffect(() => {
    const getData = async () => {
      const data = await TracksService.getTrack(trackId);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.errorCode === 13) {
        SessionService.clearSession();
      }

      if (data.results) {
        setName(() => data.results.name);
        setVariantName(() => data.results.variantName);
        setCountry(() => data.results.country);
        setTrackLength(() => data.results.trackLength);
        setTrackCenterCoordianteLong(() => data.results.trackCenterCoordianteLong);
        setTrackCenterCoordianteLat(() => data.results.trackCenterCoordianteLat);
        setCoordinates(() => data.results.coordinates);
        setStartEqualsFinish(() => data.results.startEqualsFinish);
        setStartLocationLong(() => data.results.startLocationLong);
        setStartLocationLat(() => data.results.startLocationLat);
        setStartName(() => data.results.startName);
        setStartHeading(() => data.results.startHeading);
        setFinishName(() => data.results.finishName);
        setSectors(() => data.results.sectors);
        setIsActive(() => data.results.isActive);
        setCreatedAt(() => data.results.createdAt);
        setUpdatedAt(() => data.results.updatedAt);
      }
    };

    getData();
  }, [trackId]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!name) {
      alert('Please fill all the required fields');
      return;
    }

    const track = {
      name,
      variantName,
      country,
      trackLength,
      trackCenterCoordianteLong,
      trackCenterCoordianteLat,
      coordinates,
      startEqualsFinish,
      startLocationLong,
      startLocationLat,
      startName,
      startHeading,
      finishName,
      sectors,
      isActive,
    };

    const data = await TracksService.updateTrack(trackId, track);

    if (data.results) {
      alert('Updated successfully');
    } else {
      alert('Something went wrong');
    }
  };

  const handleDelete = async e => {
    e.preventDefault();

    if (window.confirm(`Delete ${trackId} track?`)) {
      await TracksService.deleteTrack(trackId);

      alert('Removed successfully');
      navigate('/tracks');
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-white rounded-lg">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        {trackId && (
          <main className="relative flex-1 overflow-y-auto focus:outline-none">
            <div className="py-6">
              <div className="w-full px-4 sm:px-6 md:px-8">
                <h1 className="flex flex-row mb-8 text-2xl">
                  <Link to="/tracks">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 mt-2 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                  </Link>

                  <span className="mt-1">{trackId}</span>
                </h1>

                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="form-section">
                      <h2 className="pb-2 mb-4 text-lg font-semibold border-b">General</h2>
                      <FormInput label="Name" type="text" placeholder="e.g. Navak" value={name} onChange={text => setName(text)} />
                      <FormInput
                        label="Variant name"
                        type="text"
                        placeholder="e.g. International Track"
                        value={variantName}
                        onChange={text => setVariantName(text)}
                      />
                      <DropDown
                        label="Country"
                        placeholder="Please choose a country"
                        values={countriesList}
                        value={country}
                        onChange={text => setCountry(text)}
                      />
                      <Switch title="Is Active?" value={isActive} onChange={selected => setIsActive(selected)} />
                      <div className="mt-4">
                        <h3 className="text-xs">Created at {createdAt && format(new Date(createdAt), 'dd/MM/yyyy')}</h3>
                        <h3 className="text-xs">Updated at {updatedAt && format(new Date(updatedAt), 'dd/MM/yyyy')}</h3>
                      </div>
                    </div>

                    <div className="form-section">
                      <h2 className="pb-2 mb-4 text-lg font-semibold border-b">Track info</h2>
                      <FormInput
                        label="Track length"
                        type="number"
                        step="1"
                        placeholder="e.g. 2000"
                        value={trackLength}
                        onChange={text => setTrackLength(text)}
                      />
                      <FormInput
                        label="Track center coordiante long"
                        type="number"
                        step="0.000000001"
                        placeholder="e.g. -4,500715"
                        value={trackCenterCoordianteLong}
                        onChange={text => setTrackCenterCoordianteLong(text)}
                      />
                      <FormInput
                        label="Track center coordiante lat"
                        type="number"
                        step="0.000000001"
                        placeholder="e.g. 53,191258"
                        value={trackCenterCoordianteLat}
                        onChange={text => setTrackCenterCoordianteLat(text)}
                      />
                      <FormTextArea
                        label="Coordinates"
                        placeholder="e.g. 39.7931632,-86.2388662|39.7932082,-86.2388665|39.7932532,-86.2388668|39.7932981,-86.23886709999999|39.7933431,-86.23886760000001|39.7933762,-86.2388679|39.7934212,-86.2388684|39.7934661,-86.2388689|39...."
                        value={coordinates}
                        onChange={text => setCoordinates(text)}
                      />
                    </div>

                    <div className="form-section">
                      <h2 className="pb-2 mb-4 text-lg font-semibold border-b">Start info</h2>
                      <FormInput
                        label="Start location long"
                        type="number"
                        step="0.000000001"
                        placeholder="e.g. 13,9282091"
                        value={startLocationLong}
                        onChange={text => setStartLocationLong(text)}
                      />
                      <FormInput
                        label="Start location lat"
                        type="number"
                        step="0.000000001"
                        placeholder="e.g. 51,5350823"
                        value={startLocationLat}
                        onChange={text => setStartLocationLat(text)}
                      />
                      <FormInput
                        label="Start name"
                        type="text"
                        placeholder="e.g. Start name"
                        value={startName}
                        onChange={text => setStartName(text)}
                      />
                      <FormInput
                        label="Start heading"
                        type="number"
                        step="0.000000001"
                        placeholder="e.g. 77,79"
                        value={startHeading}
                        onChange={text => setStartHeading(text)}
                      />
                      <FormInput
                        label="Finish name"
                        type="text"
                        placeholder="e.g. 97,99"
                        value={finishName}
                        onChange={text => setFinishName(text)}
                      />
                      <Switch
                        title="Start equals finish?"
                        value={startEqualsFinish}
                        onChange={selected => setStartEqualsFinish(selected)}
                      />
                      {/* sectors */}
                    </div>
                  </div>

                  <div className="flex mt-4">
                    <input type="submit" value="Save" className="btn min-w-[120px]" />
                    <button type="button" className="btn-delete min-w-[120px] ml-4" onClick={handleDelete}>
                      Delete
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default TrackEdit;
