import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { format } from 'date-fns';
import { SessionService, RunsService } from '../../services';
import { Sidebar } from '../../components';
import TableSearch from '../../components/TableSearch';

const RunList = () => {
  const [runs, setRuns] = useState([]);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [pageCountShow, setPageCountShow] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageCurrent, setPageCurrent] = useState(0);
  const pageLimit = 100;

  useEffect(() => {
    const getData = async () => {
      const data = await RunsService.getAllRuns(pageCurrent, pageLimit, sortBy, sortOrder, search);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.results) {
        setRuns(() => data.results);
        setPageCount(() => data.count);
        setPageCountShow(() => Math.ceil(data.count / pageLimit));
      }
    };

    getData();
  }, [pageCurrent, sortBy, sortOrder, search]);

  const handleSearch = async text => {
    setSearch(() => text);

    const data = await RunsService.getAllRuns(pageCurrent, pageLimit, search);

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data.results) {
      setRuns(() => data.results);
      setPageCount(() => data.count);
      setPageCountShow(() => Math.ceil(data.count / pageLimit));
    }
  };

  const handlePageChange = async event => {
    const data = await RunsService.getAllRuns(event.selected, pageLimit, sortBy, sortOrder, search);

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data.results) {
      setRuns(() => data.results);
      setPageCount(() => data.count);
      setPageCountShow(() => Math.ceil(data.count / pageLimit));
      setPageCurrent(event.selected);
    }
  };

  const showAvatar = avatar => {
    return avatar ? avatar : '/avatar.jpg';
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <div className="flex items-center mt-2 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"
                  />
                </svg>
                <h1 className="text-xl font-semibold">Runs {pageCount > 0 && <span>({pageCount})</span>}</h1>
              </div>

              <TableSearch onChange={handleSearch} />

              <table className="w-full table-auto">
                <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                  <tr>
                    <th className="p-2 text-left whitespace-nowrap">ID</th>
                    <th className="p-2 text-left whitespace-nowrap">Vehicle</th>
                    <th className="p-2 text-left whitespace-nowrap">User</th>
                    <th className="p-2 text-center whitespace-nowrap">Started</th>
                    <th className="p-2 text-center whitespace-nowrap">Ended</th>
                    <th className="p-2 text-left whitespace-nowrap">Distance Trap at Distance</th>
                    <th className="p-2 text-left whitespace-nowrap">GPS Refresh Rate</th>
                    <th className="p-2 text-left whitespace-nowrap">GPS Source</th>
                    <th className="p-2 text-center whitespace-nowrap">Is Acceleration</th>
                    <th className="p-2 text-center whitespace-nowrap">Is Active</th>
                    <th className="p-2 text-center whitespace-nowrap">Is Demo</th>
                    <th className="p-2 text-left whitespace-nowrap">RT</th>
                    <th className="p-2 text-left whitespace-nowrap">Staging Distance</th>
                    <th className="p-2 text-left whitespace-nowrap">Top Speed</th>
                    <th className="p-2 text-center whitespace-nowrap">Unit</th>
                    <th className="p-2 text-center whitespace-nowrap">Video Path</th>
                    <th className="p-2 text-center whitespace-nowrap">Video Path Exported</th>
                    <th className="p-2 text-center whitespace-nowrap">Video URL on Server</th>
                    <th className="p-2 text-center whitespace-nowrap">validation</th>
                    <th className="p-2 text-center whitespace-nowrap">isValid</th>
                    <th className="p-2 text-center whitespace-nowrap">Created</th>
                    <th className="p-2 text-center whitespace-nowrap">Updated</th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-300">
                  {runs.length > 0 ? (
                    runs.map(run => (
                      <tr key={run?._id}>
                        <td className="p-2 text-left whitespace-nowrap">
                          <Link to={run?._id}>{run?._id}</Link>
                        </td>
                        <td className="p-2 text-left whitespace-nowrap">
                          {run?.vehicle?.brand} {run?.vehicle?.model} {run?.vehicle?.year}
                        </td>
                        <td className="pt-2 pb-2 pl-2 pr-6 text-left whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 mr-2 sm:mr-3">
                              <Link to={`/users/${run?.user?._id}`}>
                                <img
                                  className={`inline-block rounded-full ring-2 ring-gray-500 overflow-clip`}
                                  src={showAvatar(run?.user?.avatar)}
                                  width="24"
                                  height="24"
                                  alt={run?.user?.name}
                                />
                              </Link>
                            </div>
                            {run?.user?.name ? (
                              <div className="font-medium text-gray-800">
                                <Link to={`/users/${run?.user?._id}`}>{run?.user?.name}</Link>
                              </div>
                            ) : (
                              '/'
                            )}
                          </div>
                        </td>
                        <td className="p-2 text-center whitespace-nowrap">{run?.started && format(new Date(run.started), 'dd/MM/yyyy')}</td>
                        <td className="p-2 text-center whitespace-nowrap">{run?.ended && format(new Date(run.ended), 'dd/MM/yyyy')}</td>
                        <td className="p-2 text-left whitespace-nowrap">{run?.distanceTrapAtDistance}</td>
                        <td className="p-2 text-left whitespace-nowrap">{run?.gpsRefreshRate}</td>
                        <td className="p-2 text-left whitespace-nowrap">{run?.gpsSource}</td>
                        <td className="p-2 text-center whitespace-nowrap">{run?.isAcceleration ? 'Yes' : 'No'}</td>
                        <td className="p-2 text-center whitespace-nowrap">{run?.isActive ? 'Yes' : 'No'}</td>
                        <td className="p-2 text-center whitespace-nowrap">{run?.isDemo ? 'Yes' : 'No'}</td>
                        <td className="p-2 text-left whitespace-nowrap">{run?.rt}</td>
                        <td className="p-2 text-left whitespace-nowrap">{run?.stagingDistance}</td>
                        <td className="p-2 text-left whitespace-nowrap">{run?.topSpeed}</td>
                        <td className="p-2 text-left whitespace-nowrap">{run?.unit}</td>
                        <td className="p-2 text-center whitespace-nowrap">{run?.videoPath}</td>
                        <td className="p-2 text-center whitespace-nowrap">{run?.videoPathExported}</td>
                        <td className="p-2 text-center whitespace-nowrap">{
                          run?.videoURLOnServer && (
                            <a href={run?.videoURLOnServer} target="_blank" rel="noreferrer">
                              {run?.videoURLOnServer}
                            </a>
                          )
                        }</td>
                        <td className="p-2 text-center whitespace-nowrap">{run?.validation}</td>
                        <td className="p-2 text-center whitespace-nowrap">{run?.isValid ? 'Yes' : 'No'}</td>
                        <td className="p-2 text-center whitespace-nowrap">{format(new Date(run?.createdAt), 'dd/MM/yyyy')}</td>
                        <td className="p-2 text-center whitespace-nowrap">{format(new Date(run?.updatedAt), 'dd/MM/yyyy')}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-8 text-center text-gray-400" colSpan={13}>
                        No runs in database
                      </td>
                    </tr>
                   )}
                </tbody>
              </table>

              <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageChange}
                pageRangeDisplayed={pageCount}
                pageCount={pageCountShow}
                previousLabel="< prev"
                nextLabel="next >"
                className="flex items-center my-8"
                pageClassName="bg-gray-100 rounded-md m-1 text-sm font-semibold w-8 h-8 hover:bg-gray-200"
                pageLinkClassName="no-underline w-8 h-8 px-2 py-2 flex justify-center items-center"
                previousClassName="bg-gray-100 rounded-md m-1 text-sm font-semibold h-8 hover:bg-gray-200"
                previousLinkClassName="no-underline h-8 px-2 py-2 flex justify-center items-center"
                nextClassName="bg-gray-100 rounded-md m-1 text-sm font-semibold h-8 hover:bg-gray-200"
                nextLinkClassName="no-underline h-8 px-2 py-2 flex justify-center items-center"
                activeClassName="bg-black"
                activeLinkClassName="text-white"
                disabledClassName="bg-gray-50 hover:bg-gray-50"
                disabledLinkClassName="text-gray-400 hover:text-gray-400"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default RunList;
