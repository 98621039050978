import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { format } from 'date-fns';
import { SessionService, UserService } from '../../services';
import { Sidebar } from '../../components';
import TableSearch from '../../components/TableSearch';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [pageCountShow, setPageCountShow] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageCurrent, setPageCurrent] = useState(0);
  const pageLimit = 100;

  useEffect(() => {
    const getData = async () => {
      const data = await UserService.getAllUsers(pageCurrent, pageLimit, sortBy, sortOrder, search);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.results) {
        setUsers(() => data.results);
        setPageCount(() => data.count);
        setPageCountShow(() => Math.ceil(data.count / pageLimit));
      }
    };

    getData();
  }, [pageCurrent, sortBy, sortOrder, search]);

  const handleSearch = async text => {
    setSearch(() => text);

    const data = await UserService.getAllUsers(pageCurrent, pageLimit, sortBy, sortOrder, search);

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data.results) {
      setUsers(() => data.results);
      setPageCount(() => data.count);
      setPageCountShow(() => Math.ceil(data.count / pageLimit));
    }
  };

  const handlePageChange = async event => {
    const data = await UserService.getAllUsers(event.selected, pageLimit, sortBy, sortOrder, search);

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data.results) {
      setUsers(() => data.results);
      setPageCount(() => data.count);
      setPageCountShow(() => Math.ceil(data.count / pageLimit));
      setPageCurrent(event.selected);
    }
  };

  const showAvatar = avatar => {
    return avatar ? avatar : '/avatar.jpg';
  };

  const showAppUsed = name => {
    return `/app-${name.toLowerCase()}.jpg`;
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <div className="flex items-center mt-2 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
                <h1 className="text-xl font-semibold">Users {pageCount > 0 && <span>({pageCount})</span>}</h1>
              </div>

              <TableSearch onChange={handleSearch} />

              {/* <div className="p-2 text-xs text-left bg-gray-100 border-b">
                <div className="relative flex w-1/3 px-1 py-1 -space-y-px text-xs bg-white border border-gray-300 rounded shadow-sm">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-1 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                  <input
                    className="w-full text-gray-900 placeholder-gray-500 appearance-none focus:outline-none focus:fill-transparent focus:z-10 sm:text-xs"
                    placeholder="Search"
                    onChange={e => handleSearch(e.target.value)}
                  />
                </div>
              </div> */}

              <table className="w-full table-auto">
                <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                  <tr>
                    <th className="p-2 text-left whitespace-nowrap">Name</th>
                    <th className="p-2 text-left whitespace-nowrap">Email</th>
                    <th className="p-2 text-center whitespace-nowrap">Vehicles</th>
                    <th className="p-2 text-center whitespace-nowrap">Followers</th>
                    <th className="p-2 text-center whitespace-nowrap">Following</th>
                    <th className="p-2 text-center whitespace-nowrap">Unit</th>
                    <th className="p-2 text-center whitespace-nowrap">Pro</th>
                    <th className="p-2 text-center whitespace-nowrap">Updated</th>
                    <th className="p-2 text-center whitespace-nowrap">Created</th>
                    <th className="p-2 text-center whitespace-nowrap">Active</th>
                    <th className="p-2 text-center whitespace-nowrap">App</th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-300">
                  {users.length > 0 ? (
                    users.map(user => (
                      <tr key={user._id}>
                        <td className="p-2 text-left whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 mr-2 sm:mr-3">
                              <Link to={user._id}>
                                <img
                                  className={`inline-block rounded-full ring-2 ring-gray-500 overflow-clip`}
                                  src={showAvatar(user.avatar)}
                                  width="32"
                                  height="32"
                                  alt={user.name}
                                />
                              </Link>
                            </div>
                            {user.name ? (
                              <div className="font-medium text-gray-800">
                                <Link to={user._id}>{user.name}</Link>
                              </div>
                            ) : (
                              '/'
                            )}
                          </div>
                        </td>
                        <td className="p-2 text-left whitespace-nowrap">
                          <div className="text-left">
                            {user.email ? (
                              <a href={`mailto:${user.email}`} rel="noreferrer" className="underline text-indigo">
                                {user.email}
                              </a>
                            ) : (
                              '/'
                            )}
                          </div>
                        </td>
                        <td className="p-2 text-center whitespace-nowrap">{user.vehicles.length}</td>
                        <td className="p-2 text-center whitespace-nowrap">{user.followers.length}</td>
                        <td className="p-2 text-center whitespace-nowrap">{user.following.length}</td>
                        <td className="p-2 text-center whitespace-nowrap">{user.unit}</td>
                        <td className="p-2 text-center whitespace-nowrap">
                          <span className={`${user.isPro && 'text-green-600'}`}>{user.isPro.toString()}</span>
                        </td>
                        <td className="p-2 text-center whitespace-nowrap">{format(new Date(user.createdAt), 'dd/MM/yyyy')}</td>
                        <td className="p-2 text-center whitespace-nowrap">{format(new Date(user.updatedAt), 'dd/MM/yyyy')}</td>
                        <td className={`p-2 whitespace-nowrap text-center ${user.isActive ? 'text-green-600' : 'text-red-600'}`}>
                          {user.isActive.toString()}
                        </td>
                        <td className="p-2 text-center whitespace-nowrap">
                          {user.app &&
                            user.app.map(appName => (
                              <img
                                key={appName}
                                className={`inline-block rounded-full ring-2 ring-gray-500 overflow-clip`}
                                src={showAppUsed(appName)}
                                width="32"
                                height="32"
                                alt={appName}
                              />
                            ))}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-8 text-center text-gray-400" colSpan={13}>
                        No users in database
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageChange}
                pageRangeDisplayed={pageCount}
                pageCount={pageCountShow}
                previousLabel="< prev"
                nextLabel="next >"
                className="flex items-center my-8"
                pageClassName="bg-gray-100 rounded-md m-1 text-sm font-semibold w-8 h-8 hover:bg-gray-200"
                pageLinkClassName="no-underline w-8 h-8 px-2 py-2 flex justify-center items-center"
                previousClassName="bg-gray-100 rounded-md m-1 text-sm font-semibold h-8 hover:bg-gray-200"
                previousLinkClassName="no-underline h-8 px-2 py-2 flex justify-center items-center"
                nextClassName="bg-gray-100 rounded-md m-1 text-sm font-semibold h-8 hover:bg-gray-200"
                nextLinkClassName="no-underline h-8 px-2 py-2 flex justify-center items-center"
                activeClassName="bg-black"
                activeLinkClassName="text-white"
                disabledClassName="bg-gray-50 hover:bg-gray-50"
                disabledLinkClassName="text-gray-400 hover:text-gray-400"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default UserList;
