import React, { useId } from 'react';

const FormTextArea = ({ label, type, placeholder, required, value, min, autoComplete, onChange }) => {
  const id = useId();
  const isRequired = required && 'required';

  return (
    <div className="w-full relative px-3 py-3 border border-gray-300 rounded-md shadow-sm -space-y-px bg-white">
      <label htmlFor={id} className="block font-bold text-xs">
        {label} <span className="text-gray-600 font-normal">{required ? '*' : '(optional)'}</span>
      </label>
      <textarea
        id={id}
        name={id}
        type={type || 'text'}
        className="w-full h-16 pt-2 appearance-none placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-300 focus:border-black focus:z-10 sm:text-sm"
        placeholder={placeholder}
        value={value}
        min={min}
        autoComplete={autoComplete}
        onChange={e => onChange(e.target.value)}
        required={isRequired}
      />
    </div>
  );
};

export default FormTextArea;
