import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { RunsService, VehiclesService } from '../../services';
import { Sidebar, FormInput, DropDown, Switch } from '../../components';
import { parse, format } from 'date-fns';

const RunsList = () => {
  const navigate = useNavigate();
  const { runId } = useParams();
  const [vehicles, setVehicles] = useState([]);
  const [vehicle, setVehicle] = useState('');
  const [started, setStarted] = useState('');
  const [ended, setEnded] = useState('');
  const [distanceTrapAtDistance, setDistanceTrapAtDistance] = useState('');
  const [gpsRefreshRate, setGpsRefreshRate] = useState('');
  const [gpsSource, setGpsSource] = useState('');
  const [isAcceleration, setIsAcceleration] = useState('');
  const [isActive, setIsActive] = useState('');
  const [isDemo, setIsDemo] = useState('');
  const [rt, setRt] = useState('');
  const [stagingDistance, setStagingDistance] = useState('');
  const [topSpeed, setTopSpeed] = useState('');
  const [speedPairs, setSpeedPairs] = useState([]);
  const [unit, setUnit] = useState('imperial');
  const [validation, setValidation] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [videoURLOnServer, setVideoURLOnServer] = useState('');

  useEffect(() => {
    const getData = async () => {
      const data = await RunsService.getRun(runId);

      if (data.status === 500) {
        RunsService.clearRun();
      }

      if (data.errorCode === 13) {
        RunsService.clearRun();
      }

      if (data.results) {
        setVehicle(() => data.results.vehicle || '');
        setStarted(() => data.results.started || '');
        setEnded(() => data.results.ended || '');
        setDistanceTrapAtDistance(() => data.results.distanceTrapAtDistance || '');
        setGpsRefreshRate(() => data.results.gpsRefreshRate || '');
        setGpsSource(() => data.results.gpsSource || '');
        setRt(() => data.results.rt || '');
        setStagingDistance(() => data.results.stagingDistance || '');
        setTopSpeed(() => data.results.topSpeed || '');
        setSpeedPairs(() => data.results.speedPairs || []);
        setUnit(() => data.results.unit || 'imperial');
        setValidation(() => data.results.validation || '');
        setIsValid(() => data.results.isValid || false);
        setIsAcceleration(() => data.results.isAcceleration || false);
        setIsActive(() => data.results.isActive || false);
        setIsDemo(() => data.results.isDemo || false);
        setVideoURLOnServer(() => data.results.videoURLOnServer || '');
      }
    };

    const getVehiclesData = async () => {
      const data = await VehiclesService.getAllVehicles(0, 100000);

      if (data.status === 500) {
        RunsService.clearRun();
      }

      if (data.errorCode === 13) {
        RunsService.clearRun();
      }

      if (data.results) {
        const vehiclesList = [];
        for (const vehicle of data.results) {
          vehiclesList.push({
            key: vehicle._id,
            value: `${vehicle.brand?.name}, ${vehicle.model?.name}, ${vehicle.year}, User: ${vehicle.user?.name}`,
          });
        }

        setVehicles(() => vehiclesList);
      }
    };

    getData();
    getVehiclesData();
  }, [runId]);

  const handleSubmit = async e => {
    e.preventDefault();

    const run = {
      vehicle,
      started,
      ended,
      distanceTrapAtDistance,
      gpsRefreshRate,
      gpsSource,
      isAcceleration,
      isActive,
      isDemo,
      rt,
      stagingDistance,
      topSpeed,
      unit,
      validation,
      isValid,
    };
    const data = await RunsService.updateRun(runId, run);

    if (data.results) {
      alert('Updated successfully');
    } else {
      alert('Something went wrong');
    }
  };

  const handleDelete = async e => {
    e.preventDefault();

    if (window.confirm(`Delete ${runId}'s run?`)) {
      await RunsService.deleteRun(runId);

      alert('Removed successfully');
      navigate('/runs');
    }
  };

  const formatDateTime = (date, source) => {
    if (date && source === 'ui') {
      return format(parse(date, 'yyyy-MM-dd', new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    }

    if (date) {
      return format(parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date()), 'yyyy-MM-dd');
    }
  };

  const handleStartedDateChange = value => {
    const newDate = formatDateTime(value, 'ui');
    setStarted(() => newDate);
  };

  const handleEndedDateChange = value => {
    const newDate = formatDateTime(value, 'ui');
    setEnded(() => newDate);
  };

  return (
    <div className="flex h-screen overflow-hidden bg-white rounded-lg">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        {runId && (
          <main className="relative flex-1 overflow-y-auto focus:outline-none">
            <div className="py-6">
              <div className="w-full px-4 sm:px-6 md:px-8">
                <h1 className="flex flex-row mb-8 text-2xl">
                  <Link to="/runs">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 mt-2 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                  </Link>

                  <span className="mt-1">{runId}</span>
                </h1>

                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="form-section">
                      <h2 className="pb-2 mb-4 text-lg font-semibold border-b">General</h2>

                      {videoURLOnServer && (
                        <video controls className="object-contain w-full mb-4 rounded-md">
                          <source src={videoURLOnServer} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}

                      <DropDown
                        label="Vehicle"
                        placeholder="Please choose a vehicle"
                        values={vehicles}
                        value={vehicle}
                        showEmpty={true}
                        onChange={text => setVehicle(text)}
                      />

                      <FormInput
                        type="date"
                        label="Started"
                        placeholder="Please enter started"
                        value={started && formatDateTime(started)}
                        onChange={text => handleStartedDateChange(text)}
                        required
                      />
                      <FormInput
                        type="date"
                        label="Ended"
                        placeholder="Please enter ended"
                        value={ended && formatDateTime(ended)}
                        onChange={text => handleEndedDateChange(text)}
                        required
                      />
                      <FormInput
                        type="number"
                        label="Distance trap at distance"
                        placeholder="Please enter distance trap at distance"
                        value={distanceTrapAtDistance}
                        onChange={text => setDistanceTrapAtDistance(text)}
                      />
                      <FormInput
                        type="number"
                        label="GPS refresh rate"
                        placeholder="Please enter GPS refresh rate"
                        value={gpsRefreshRate}
                        onChange={text => setGpsRefreshRate(text)}
                      />
                      <FormInput
                        type="text"
                        label="GPS source"
                        placeholder="Please enter GPS source"
                        value={gpsSource}
                        onChange={text => setGpsSource(text)}
                      />
                      <FormInput type="number" label="RT" placeholder="Please enter RT" value={rt} onChange={text => setRt(text)} />
                      <FormInput
                        type="number"
                        label="Staging distance"
                        placeholder="Please enter staging distance"
                        value={stagingDistance}
                        onChange={text => setStagingDistance(text)}
                      />
                      <FormInput
                        type="number"
                        label="Top speed"
                        placeholder="Please enter top speed"
                        value={topSpeed}
                        onChange={text => setTopSpeed(text)}
                      />

                      <DropDown
                        label="Unit"
                        placeholder="Please choose a unit"
                        values={[
                          {
                            key: 'imperial',
                            value: 'imperial',
                          },
                          {
                            key: 'metric',
                            value: 'metric',
                          },
                        ]}
                        value={unit}
                        onChange={text => setUnit(text)}
                      />

                      <FormInput
                        type="text"
                        label="Validation"
                        placeholder="Please enter validation"
                        value={validation}
                        onChange={text => setValidation(text)}
                      />

                      <Switch title="Is valid" value={isValid} onChange={value => setIsValid(value)} />

                      <Switch title="Is acceleration" value={isAcceleration} onChange={value => setIsAcceleration(value)} />
                      <Switch title="Is demo" value={isDemo} onChange={value => setIsDemo(value)} />
                      <Switch title="Is active" value={isActive} onChange={value => setIsActive(value)} />
                    </div>
                  </div>

                  <div className="flex mt-4">
                    <input type="submit" value="Save" className="btn min-w-[120px]" />
                    <button type="button" className="btn-delete min-w-[120px] ml-4" onClick={handleDelete}>
                      Delete
                    </button>
                  </div>
                </form>

                <h4 className="mt-16 mb-4 font-semibold text-md">
                  Speed Pairs {speedPairs.length > 0 && <span>({speedPairs.length})</span>}
                </h4>
                <table className="w-full table-auto">
                  <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                    <tr>
                      <th className="p-2 text-left whitespace-nowrap">ID</th>
                      <th className="p-2 text-left whitespace-nowrap">Speed start</th>
                      <th className="p-2 text-left whitespace-nowrap">Speed end</th>
                      <th className="p-2 text-left whitespace-nowrap">Time</th>
                      <th className="p-2 text-left whitespace-nowrap">Distance</th>
                      <th className="p-2 text-left whitespace-nowrap">Type</th>
                      <th className="p-2 text-left whitespace-nowrap">Metric</th>
                      <th className="p-2 text-left whitespace-nowrap">Start real timestamp</th>
                      <th className="p-2 text-left whitespace-nowrap">End real timestamp</th>
                      <th className="p-2 text-left whitespace-nowrap">RT</th>
                      <th className="p-2 text-left whitespace-nowrap">Is rollout enabled</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-300">
                    {speedPairs.length > 0 ? (
                      speedPairs.map(speedPair => (
                        <tr key={speedPair._id}>
                          <td className="p-2 whitespace-nowrap">{speedPair._id}</td>
                          <td className="p-2 whitespace-nowrap">{speedPair.speedStart}</td>
                          <td className="p-2 whitespace-nowrap">{speedPair.speedEnd}</td>
                          <td className="p-2 whitespace-nowrap">{speedPair.time}</td>
                          <td className="p-2 whitespace-nowrap">{speedPair.distance}</td>
                          <td className="p-2 whitespace-nowrap">{speedPair.type}</td>
                          <td className="p-2 whitespace-nowrap">{speedPair.metric}</td>
                          <td className="p-2 whitespace-nowrap">{speedPair.startRealTimestamp}</td>
                          <td className="p-2 whitespace-nowrap">{speedPair.endRealTimestamp}</td>
                          <td className="p-2 whitespace-nowrap">{speedPair.rt}</td>
                          <td className="p-2 whitespace-nowrap">{speedPair.isRolloutEnabled}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-8 text-center text-gray-400" colSpan={13}>
                          No laps in session
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default RunsList;
